<template>
  <form @submit.prevent="handleSubmit">
    <div class="form-row">
      <label class="col-3 text-right" for="ZipInput">Your ZIP:</label>
      <div class="col-5">
        <input type="text" placeholder="Enter Zip" id="ZipInput" v-model="form.zip" @blur="validateForm"
          @input="form.isValid = true" />
      </div>
    </div>
    <div class="form-row">
      <label class="col-3 text-right" for="SeatsInput">Seats:</label>
      <div class="col-4">
        <select id="SeatsInput" v-model="form.seats">
          <option v-for="seat in seatOptions" :key="seat" :value="seat">
            {{ seat }} or more
          </option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <label class="col-3 text-right" for="PricePicker">Pricing:</label>
      <price-picker id="PricePicker" v-model="form.price" />
      <span class="price-range-text">{{ priceRangeLowText }}-{{ priceRangeHighText }}</span>
    </div>
    <div class="form-row">
      <label class="col-3 text-right" for="RangeInput">Range:</label>
      <div class="col-4">
        <select id="RangeInput" v-model="form.range">
          <option v-for="range in rangeOptions" :key="range" :value="range">
            {{ range }} miles per charge
          </option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <label class="col-3 text-right" for="BodyStyleInput">Body Styles:</label>
      <div class="col-8">
        <BodyStylePicker id="BodyStyleInput" v-model="form.bodyStyles" />

      </div>
    </div>
    <div class="form-row">
      <div class="offset-3 col-2">
        <button type="submit" class="btn button small">
          Update
        </button>
      </div>
    </div>
    <div class="form-row error" v-show="form.isValid == false">
      <div class="col-8 offset-4">
        Please enter a valid ZIP {{ form.isValid }}
      </div>
    </div>
  </form>
</template>
<script>
const BodyStylePicker = () => import("Components/VehicleSearch/BodyStylePicker");
const PricePicker = () => import("Components/VehicleSearch/PricePicker");
import { Constants } from "Services/Constants";
import common from "Mixins/common";

export default {
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        zip: this.$store.state.searchOptions?.zip,
        seats: this.$store.state.searchOptions?.seatingCapacity,
        price: {
          min: this.$store.state.searchOptions?.priceLow,
          max: this.$store.state.searchOptions?.priceHigh,
        },
        range: this.$store.state.searchOptions?.dailyDistance,
        bodyStyles: this.$store.state.searchOptions?.bodyStyles || [],
        isValid: true,
      },
      priceOptions: Constants.priceOptions,
      seatOptions: Constants.seatOptions,
      rangeOptions: Constants.driveDistanceOptions,
    };
  },
  methods: {
    handleSubmit() {
      this.validateForm();
      if (this.form.isValid == true) {
        this.$emit("update:search", this.form);
        this.$store.dispatch("loadComparisonData", this.form);
      }
    },
    validateForm() {
      this.form.isValid = this.form.zip && this.form.zip.toString().length == 5 && !isNaN(this.form.zip);
    }
  },
  computed: {
    availableMaxPriceOptions() {
      return this.priceOptions.filter((x) => x > this.form.price.min);
    },
    priceRangeLowText() {
      const priceRangeThousands = this.form.price.min / 1000;
      return `$${priceRangeThousands}k`;
    },
    priceRangeHighText() {
      const priceRangeThousands = this.form.price.max / 1000;
      return `$${priceRangeThousands}k`;
    }
  },
  components: { BodyStylePicker, PricePicker },
  mixins: [common],
};
</script>

<style lang="scss" scoped>
div {
  color: black;
}

label {
  padding-top: 2px;
  color: black;
}

label,
.dash {
  color: #3b9bf5;
  font-size: 0.75rem;
}

input,
select,
::v-deep .select-dropdown .faux-select {
  font-size: 0.75rem;
  margin-bottom: 10px;
}

::v-deep .select-dropdown .faux-select {
  padding: 0 0 0 5px;
  height: 20px;
  border-radius: 0;
  border-color: black;
}

::v-deep .select-dropdown-content label {
  font-size: 0.75rem !important;
}

::v-deep .dropdown {
  top: 3px !important;
}

.dash {
  position: relative;
  left: 5px;
  padding-left: 8px;
  padding-top: 1px;
  padding-right: 4px;
}

.btn.button.small {
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 0.75rem;
  padding: 1px 0px 1px 0px !important;
  width: 55px;
}
#PricePicker {
  padding-left: 3px;
  width: 50%;
  margin-right: 10px;
}

.price-range-text {
  font-size: 0.65rem;
  top: 4px;
  position: relative;

}
</style>
